@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700);

.react-tabs__tab--selected{
    font-weight: bold;
}
.react-tabs__tab{
    padding: 6px 36px !important;
}

.inputBox{
    background: #fff;
    border: 2px solid gray;
    color: gray;
    font-weight: 600;
    padding-top: 10px !important;
    border-radius: 8px;
    height: 50px !important;
    padding-left: 20px !important;
    padding-right: 10px !important;
}

.inputBox[placeholder]:empty:before {
    content: attr(placeholder);
    color: grey; 
}

.inputBox[placeholder]:empty:focus:before {
    content: "";
}

.otpBox {
    padding-left: 120px !important;
}

.smallBox{
    background: #fff;
    border: 2px solid gray;
    color: gray;
    font-weight: 600;
    padding-top: 10px !important;
    border-radius: 8px;
    height: 50px !important;
    padding-right: 5px !important;   
    padding-left: none !important;   
    left: 30 !important;
    width: 100% !important;
}

.smallBox[placeholder]:empty:before {
    content: attr(placeholder);
    color: grey; 
}

.smallBox[placeholder]:empty:focus:before {
    content: "";
}

.card-details-form {
    left: 0px;
    right: 0px;
    padding-right: 4px;
}

.security-code {
    left: 15px;
}

[contenteditable="true"].single-line {
    white-space: nowrap;
    overflow: hidden;
} 
[contenteditable="true"].single-line br {
    display:none;

}
[contenteditable="true"].single-line * {
    display:inline;
    white-space:nowrap;
}

.testDataBox{
    background: #fff;
    border: 2px solid gray;
    color: gray;
    font-weight: 600;
    padding-top: 10px !important;
    border-radius: 8px;
    padding-right: 5px !important;   
    padding-left: none !important;   
    left: 30 !important;
    width: 100% !important;
}


.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      -webkit-animation: App-logo-spin infinite 20s linear;
              animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
#loader-text {
	font-weight: 600;
	color: #f8f9fa;
}
#loader {
	margin-top: 330px;
	position: fixed;
	margin-left: 47%;
}
#loader-div {
	background-color: rgba(0, 0, 0, 0.5);
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 9991 ;
}
@media (min-width: 350px) and (max-width: 950px) {
	#loader-div {
		background-color: rgba(0, 0, 0, 0.5);
		height: 100%;
		width: 100%;
		position: absolute;
	}
	#loader {
		margin-top: 80%;
		position: fixed;
		margin-left: 44%;
	}
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
@media (min-width: 768px) {
  .checkout-page-container {
    width: 375px !important;
    
    border-top: 1px solid #b7b2b2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 1px solid #b7b2b2;
    border-right: 1px solid #b7b2b2;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #b7b2b2;
  }

  .footer-navbar{
    width: 375px !important;
    margin: 0 auto;
    border-bottom: 1px solid #b7b2b2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 1px solid #b7b2b2;
    border-right: 1px solid #b7b2b2;
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom: 10px;
  }
}
/* // Fonts faces and family */
/* // @import url('://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700'); */

/* $fa-font-path: './../assets/web-fonts';
$font-family: 'Open Sans', sans-serif; */

html,
body {
  font-family: 'Open Sans', sans-serif;
  background-color:  #ffffff;
  scroll-behavior: smooth;
  margin: 0;
}
.developerMode{
  font-size: 14px;
  font-weight: bold;
  float: right;
}
.androidSdk, .iosSdk{
  cursor: pointer;
}
.disableSDK{
    opacity: 0.5;    
    cursor: no-drop;
}
.configError{
  position: fixed;
  z-index: 1;
  width: 40%;
  right: 5px;
  top: 20px;
}.forgotPasswordPolicy {
  padding-left: 0px;
}
.Toastify__toast-body {
  font-size: 12px !important;
  font-weight: 1000 !important;
}
.pagination .active a {
  background-color: #007bff !important;
  color: white;
}
.active:after {
  content: none !important;
}
.NavMenuNavigation {
  font-size: 30px;
  color: #ffffff;
  padding-bottom: 0;
  cursor: pointer;
  font-size: 20px;
}
.notify {
  background: #277a53 !important;
  margin-left: 60%;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  padding: 3px;
  border: none;
}
.Toastify__toast--success {
  background: #277a53;
}
.Toastify__toast-container .msg {
text-align: center;
  font-size: 12px;
}
.Toastify__toast-container {
  width: 30% !important;
}
 .forgotPassword .box input {
  width: 90% !important;
} 
.forgotPassword .info {
  margin-left: 15px;
}
/* Responsive Screen */
/* @media screen and (max-width:1000px) {
    #basicLayout {
      width:100%; /* The width is 100%, when the viewport is 800px or smaller */
/* }
    .checkout-box{
        margin-left: 4% !important;
    }
    .SRCIcon{
        width: 40px !important;
    }
    .checkout-navbar-title {
        font-size: 12px !important;
    }
    .icon-badge-link svg{
        font-size: 15px !important;
    }
    .checkout-page-yourcart-title{
        font-size: 15px !important;
    }
    .yourCartTxt{
        margin-top: -15px !important;
    }
    .checkout-page-items{
        font-size: 13px !important;
        margin-bottom: 0px !important;
    }
    .checkout-page-cart-item{
        width: 125px ;
    }
    .checkout-page-cart-item-name{
        font-size: 13px !important;
    }
    .checkout-page-cart-item-jewels{
        font-size: 12px !important;
    }
    .checkout-page-cart-item-jewels-price{
        font-size: 12px !important;
    }
    .checkout-page-cart-item-jewels-total-price{
        font-size: 15px !important;
    }
    .checkout-page-logo{
        width: 175px;
    }
} */
/* End of Responsive Screen */
/* Configure Button */
.addCardConfig {
  margin-top: -375px !important;
}
.comIdentityConfig {
  margin-top: -300px !important;
}
.profileConfig {
  margin-top: -150px !important;
}
.thankYouConfig {
  margin-top: -575px !important;
}
.paymentcardConfig {
  margin-top: -400px !important;
}
/* Ref Profile */
.page-container {
  padding-bottom: 0px !important;
}
.checkout-page-container {
  padding: 10px;
}
.LogWindow {
  padding-right: 0px;
}
.parent-card {
  margin-top: 10px;
  /* border: #ccc solid 1px;
    border-radius: 10px; */
}
.title-card {
  margin-top: 16px;
}
.addCard {
  margin-bottom: 15px;
}
/* End Ref Profile */
.ConfigureWindow .bm-burger-button {
}
.Filter {
  /* padding-right: 5%; */
  margin-bottom: 25px;
}
/* Switch */
.switch-field {
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  padding-right: 2px;
  float: right;
}

.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
}

.switch-field label {
  background-color: #e4e4e4;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  line-height: 1;
  text-align: center;
  padding: 8px 16px;
  margin-right: -1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
}

.switch-field input:checked + label {
  background-color: #a5dc86;
  box-shadow: none;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

/* Pagination */
.pagination {
  padding-left: 30px !important;
  float: right;
  margin-right: 90px;
  margin-top: 10px;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #337ab7;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
/* Right NavBar */
.configWindow .bm-overlay {
  left: 0;
  top: 10px;
}
.configWindow .bm-menu-wrap {
  top: 50px !important;
  height: 460px !important;
  background-color: white;
  overflow-y: auto;
  width: 57% !important;
}
.configWindow .header {
  text-align: center !important;
  margin-top: 10px;
  margin-bottom: 25px;
  color: black;
  letter-spacing: 0.15em;
  font-size: 15px;
  font-weight: 600;
}
.configWindow label {
  font-size: 15px;
  font-weight: 600;
  color: black;
}
.configWindow .bm-item {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 0px;
  text-align: left;
}
.configWindow .bm-item .card {
  margin-bottom: 18px;
  border-radius: 0;
  font-size: 12px;
}
.configWindow .bm-item .card .card-header {
  width: auto;
  background-color: green;
  color: white;
}
.configWindow .bm-item .card .card-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.configWindow tbody {
  display: table-row-group !important;
  background-color: white;
}
.configWindow tr td {
  background-color: white;
}

/* Left Navbar */
.SidebarMenu .bm-item {
  display: inline-block;
  font-size: 16px;
  text-decoration: none !important;
  margin-bottom: 10px;
  color: black;
  transition: color 0.2s;
}
.SidebarMenu .bm-item:hover {
  color: green;
}
.SidebarMenu .sideIcon {
  margin-right: 15px;
}
.SidebarMenu .avatar {
  margin-right: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.userName {
  color: black;
}
.userProfile {
  margin-bottom: 30px;
}
/* Position and sizing of burger button */
.bm-burger-button {
  z-index: 1000;
  position: absolute;
  width: 20px;
  height: 18px;
}
/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}
/* Position and sizing of clickable cross button */
.SidebarMenu .bm-cross-button {
  left: 85%;
  margin-top: 20px !important;
  height: 24px;
  width: 24px;
}
/* Color/shape of close button cross */
.SidebarMenu .bm-cross {
  background: #bdc3c7;
}
/* General sidebar styles */
.SidebarMenu .bm-menu {
  background: white;
  padding: 20px 20px 0 20px;
  font-size: 1.15em;
}
.SidebarMenu .bm-menu-wrap {
  left: 0px !important;
}
#checkoutTrigger {
  padding-left: 0px !important;
}
/* Morph shape necessary with bubble or elastic */
.SidebarMenu .bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.SidebarMenu .bm-item-list {
  color: #b8b7ad;
}
/* CheckOut Box */
.checkout-page-title {
  font-size: 15px !important;
}
.checkout-items {
  font-size: 13px !important;
  margin-bottom: 0px !important;
}
.checkout-page-item-name {
  font-size: 13px !important;
}
.checkout-page-item-jewels {
  font-size: 12px !important;
}
.checkout-page-item-jewels-price {
  font-size: 12px !important;
}
.checkout-page-item-jewels-total-price {
  font-size: 15px !important;
}
.checkout-box {
  margin-top: 15px;
  margin-left: 50px;
  width: 300px !important;
}
.checkout-box2 {
  margin-top: 15px;
  margin-left: 35px;
  width: 342px !important;
}
.checkout-container {
  border-right: #ccc solid 3px;
}
.NavBar {
  position: relative;
}
.bg-div {
  box-shadow: 0 2px 2px -2px rgba(8, 105, 13, 0.95);
}
.navBrand {
  margin-left: 25%;
}
/* Styling of overlay */
.SidebarMenu .bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.checkout-navbar-title {
  font-size: 16px;
  font-weight: 800;
  line-height: 1.5;
  letter-spacing: 0.1em;
}

.checkout-navbar,
.paymentcard-navbar {
  padding: 0.1rem 0.1rem;
  background-color: #fff !important;
}
.checkout-navabar-dark {
  padding: 0.1rem 0.1rem;
  background-color: #000 !important;
  border-bottom: 1px solid gray;
  color: #fff;
}
.line-height-0 {
  line-height: 0;
}
.paymentcard-navbar {
  border-bottom: 1px solid gray;
}

.footer-navbar-copyright {
  font-size: 14px;
  color: #000;
}
.hr-line {
  margin-top: 0px;
  margin-bottom: 7px;
}
.hr-line-dot {
  border: 0.5px dashed #8c8b8b;
}

/* .col-border-bottom-line-dot {
    border-bottom: 0.5px dashed #8c8b8b;
} */

.checkout-page-yourcart-title {
  letter-spacing: 0.2em;
  font-size: 20px;
  font-weight: 800;
}
.checkout-page-title {
  letter-spacing: 0.2em;
  font-size: 18px;
  font-weight: 800;
}
.shipping-address-title,
.shipping-address,
.payment-method-title {
  padding-left: 2rem;
  letter-spacing: 0.15em;
}

.payment-method {
  padding-left: 2rem;
}
.checkout-page-yourcart-subtitle {
  letter-spacing: 0.02em;
  font-size: 15px;
  font-weight: 700;
}
.checkout-page-subtitle {
  font-size: 15px !important;
}

.footer {
  background: #f3f1ee;
  border-bottom: #ccc solid 1px;
  border-right: #ccc solid 1px;
  border-left: #ccc solid 1px;
}
.FooterCheckout {
  margin-top: -25px;
}
.footer-navbar-links {
  padding-top: 5px;
  font-weight: 600;
  font-size: 12px;
  white-space: nowrap;
}
.headerName {
  letter-spacing: 0.15em;
  font-size: 20px;
  font-weight: 600;
}
#checkoutTrigger .LogsContainer {
  height: auto;
}
.LogsContainer {
  height: auto;
}
.innerLogsContainer {
  padding-top: 10px;
  max-height: 100%;
}
.btnDownload {
  border-radius: 100%;
  border: none;
}
/* .hr {
    width: 950px;
} */
#expList {
  margin-top: 10px;
  padding-left: 20px;
}
#expList ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 12px;
}
#expList p {
  margin: 0;
  display: block;
}
#expList li {
  /* line-height:140%;
    text-indent:0px;
    background-position: 1px 8px; 
    padding-left: 10px !important;*/
  background-repeat: no-repeat;
}
.LogTree li {
  list-style-type: none;
  margin-top: 10px;
  font-size: 12px;
}
.LogTree li a {
  text-decoration: none;
  color: black;
  margin-right: 10px;
  padding: 0 3px;
  font-size: 13px;
}
.logOption {
  background-color: #f2f2f2;
}
.API {
  background: #b9dfcd;
}
.dcf {
  background-color: darkkhaki;
}

.ServerToServerAPI {
  background-color : #bd213045;
}
.srciTransactionId {
  color: darkcyan !important;
}

/* .delimiter {
  //  border-right: 1px solid black;
} */

.checkout-cart-badge {
  position: absolute;
  top: 8px;
  background: red;
  width: 18px;
  height: 18px;
  color: #fff;
  border-radius: 30px;
  text-align: center;
  left: 38px;
  font-size: 12px;
  margin-left: 10px;
}

.btn-checkout {
  background-color: #0b7757;
  border-color: #0b7757;
  color: #fff;
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 0.05em;
}

.checkout-page-items {
  font-weight: 800;
  letter-spacing: 0.2em;
  font-size: 16px;
}

.checkout-page-cart-item {
  padding: 10px;
  margin-left: 10px;
}

/* .checkout-navbar-icon {
   
} */

.icon-badge-link svg {
  font-size: 20px;
  color: #000;
}

.checkout-page-cart-item-name {
  font-weight: 700;
  text-decoration: underline;
  font-size: 15px;
}

.checkout-page-cart-item-jewels {
  font-size: 14px;
  line-height: 0.8em;
}

.checkout-page-cart-item-jewels .property-name {
  font-weight: 800;
}

.checkout-page-cart-item-jewels .property-value {
  font-weight: 600;
}

.checkout-page-cart-item-jewels-price {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.08em;
}

.checkout-page-cart-item-jewels-total-price {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.08em;
}

/**************************/

.paymentcard-page-title {
  font-size: 18px;
  line-height: 2rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
}

.paymentcard-page-subtitle {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01rem;
}

.card-details {
  background: #fdfbf9;
  border-top: 1px solid #b9b0b0;
}

.card-details-title {
  font-size: 16px;
  font-weight: 600;
}

.link-to {
  color: #fff;
}
.link-to:hover,
.btn-continue:hover,
.bth-checkou:hover {
  color: #fff;
  text-decoration: none;
}
.card-details-form,
.card-details-container-inner,
.btn-continue-row {
  background: #fdfbf9;
}
.card-details-container-inner {
  padding-bottom: 0px;
  padding-right: 5px;
  padding-left: 5px;
}
.billing-address-form input,
.billing-address-form select {
  background: #fff;
  border: 2px solid gray;
  color: gray;
  font-weight: 600;
  border-radius: 8px;
}

.billing-address-form select {
  height: 50px;
}

.card-details-form input {
  background: #fdfbf9;
  border: 2px solid gray;
  color: gray;
  font-weight: 600;
  border-radius: 8px;
}

.btn-continue {
  background-color: #000;
  border-color: #000;
  color: #fff;
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 0.05em;
}

.btn-continue-row,
.btn-continue-row-billing-address {
  padding-bottom: 30%;
}

/*****/
.payment-card-title-with {
  font-size: 14px;
  font-weight: 600;
}
.payment-card-mycard {
  line-height: 0.2em;
}

.payment-card-mycard-name {
  font-size: 14px;
  font-weight: 800;
}

.payment-card-price-name {
  font-size: 14px;
  font-weight: 700;
}

.payment-card-price {
  font-size: 14px;
  font-weight: 800;
}

.payment-card-price-value {
  font-size: 8px;
}

.payment-card-mycard-short-number {
  font-size: 12px;
}

.sms-info {
  font-size: 12px;
}

.payment-confirmation-title {
  font-size: 18px;
  font-weight: 600;
  padding: 1em;
}

.having-trouble {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
}

.btn-continue-row-otp {
  padding-top: 30%;
}

.payment-card-mycard-link {
  right: 40px;
  top: 5px;
}

.white-space-nowrap {
  white-space: nowrap;
}
.text-decoration-underline {
  text-decoration: underline;
}

.validation-msg {
  color: red;
}

.vl {
  border-left: 2px solid black;
  height: 45px;
  margin-right: 10px;
}

.margingLeft27px {
  margin-left: -27px;
}

.marginRight10px {
  margin-right: 10px;
}

.margingLeft11px {
  margin-left: -11px;
}

.profile-list-page {
  height: 343px;
}

.returningUser {
  background-color: rgba(0, 0, 0, 0.26);
  cursor: not-allowed;
}

.textarea {
  height: calc(1.5em + 0.75rem + 2px) !important;
  overflow: hidden;
  resize: none;
  background: #fdfbf9;
  border: 2px solid gray;
  color: gray;
  font-weight: 600;
  border-radius: 8px;
  font-family: inherit;
}

.scenario-button {
  border-radius: 30px;
}

.marginTop110px {
  margin-top: 110px !important;
}

.srciTesttinPTag {
  font-weight: 600;
  text-align: center !important;
  margin-top: 8px;
}

.poweredText {
  font-size: 12px;
  font-weight: 500;
}

.marginLeft88px {
  margin-left: 88px;
}

.modal {
  display: none; /* Hidden by default */
  /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
/* Modal Content */
.modal-content {
  background-color: #e8edf2;
  /* margin: auto; */
  padding: 20px;
  border: 1px solid #888;
  width: 35%;
  margin-left: 13px;
}
/* The Close Button */
.close {
  color: #aaaaaa;
  position: absolute;
  right: 22px;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser’s default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: “”;
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.main {
  background-color: #ffffff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: center;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}
/* Hide the default checkbox */
input[type="checkbox"] {
  visibility: hidden;
}
/* Creating a custom checkbox
based on demand */
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: black;
}
/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input ~ .geekmark {
  background-color: yellow;
}
/* Specify the background color to be
shown when checkbox is active */
.main input:active ~ .geekmark {
  background-color: red;
}
/* Specify the background color to be
shown when checkbox is checked */
.main input:checked ~ .geekmark {
  background-color: green;
}
/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: “”;
  position: absolute;
  display: none;
}
/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
  display: block;
}
/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
  left: 8px;
  bottom: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.accordion {
  background-color: #ffffff;
  color: #444;
  cursor: pointer;
  border: 1px solid black;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: right;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}
.active,
.accordion:hover {
  background-color: #ffffff;
}
.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}
.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-right: 2px;
}
.submitbtn {
  border: none;
  color: black;
  padding: 12px 16px;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 50%;
}

.sidebar-container {
  min-height: 100vh;
  background-color: lightgray;
}

.sidebar {
  width: 200px;
  padding-top: 25px;
}

.sidebar-link {
  padding: 10px;
}

.sidebar-link :hover {
  border-right: 5px solid dimgray;
  background-color: gainsboro;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.marginTop14px {
  margin-top: 14px;
}

.collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #555;
}

.collapsible:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.textAlignCentre {
  text-align: center;
  -webkit-column-span: 3;
          column-span: 3;
}

.cursorPointer {
  cursor: pointer;
}

tbody {
  background-color: green;
  display: block;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

#btnConfiguration {
  float: right;
  z-index: 500;
  background-color: #0fa377;
  color: white;
  border-radius: 0px;
  border-color: #0fa377;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-right: -65px;
  margin-top: -45px;
  margin-left: -50px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
}

#btnConfiguration:hover {
  background-color: white;
  color: #0fa377;
  border-color: #0fa377;
}

.marginLeft15px {
  margin-left: 15px;
}

.marginTop12px {
  margin-top: 12px;
}

.cardIcon {
  height: 113%;
  width: 103%;
  margin-top: -6%;
  padding-left: inherit;
  padding-bottom: 20px;
  cursor: pointer;
}

.profile-list-page {
  overflow: auto;
  height: auto;
  overflow-x: hidden;
  width: auto;
  max-height: 247px;
}

.margin-bottom-35percent {
  margin-bottom: 35%;
}

.btnDirectCheckout {
  /* float: right;
    margin-top: -55px;
    margin-right: -3px; */
}

.width111px {
  width: 111px;
}

.dashboardPageLogo {
  margin-top: -27px;
  position: absolute;
  border: -1px solid;
  margin-left: 13px;
}

.SRCISdkUrl {
  text-align: left;
  margin-top: 15px;
  color: #17a05a !important;
  margin-bottom: 6px;
  margin-top: 29px;
}

.content-color {
  background-color: #f9fbfc;
}

.SRCISdkUrlValue {
  text-align: left;
}
.environmentText {
  text-align: left;
  margin-top: 20px;
  color: #17a05a !important;
}

.width50Percent {
  width: 50%;
}

.box,
.landingPage {
  margin-bottom: 30px;
}
.using {
  font-weight: 600;
}
.box input {
  padding: 10px 0;
  margin-bottom: 30px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  border-bottom: 2px solid #999;
}
.box hr {
  width: 50%;
  text-align: center;
}
.box input:focus {
  border-bottom: 2px solid green;
}
.btn-login {
  background-color: #00b300;
  color: #fff;
  font-size: 15px;
  line-height: 1.8rem;
  letter-spacing: 0.05em;
}
.btn-login:hover {
  color: #fff;
  font-weight: bold;
}
.awsCognito {
  width: 50px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.or {
  color: black;
  font-weight: bold;
  margin: 20px;
}
.select {
  border: none;
  outline: none;
  box-shadow: none !important;
  border-bottom: 2px solid #999;
  border-radius: 0;
}
.landingPage hr {
  width: 70%;
  text-align: center;
}
.awsLogo p {
  font-size: 15px;
  margin-bottom: 0px;
}
.testType {
  margin: 15px;
}
.radio-toolbar {
  margin: 10px;
}
.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}
.radio-toolbar label {
  display: inline-block;
  background-color: #ddd;
  padding: 10px 20px;
  font-family: sans-serif, Arial;
  font-size: 16px;
  border: 2px solid #444;
  border-radius: 4px;
}
.radio-toolbar label:hover {
  background-color: #dfd;
}
.radio-toolbar input[type="radio"]:checked + label {
  background-color: #bfb;
  border-color: #4c4;
}

.checkout-box2 .btnSignout {
  margin-left: 0px !important;
}
.checkout-box2 .srcIcon {
  height: 25px !important;
}
.checkout-box2 .discoverLogo {
  height: 25px !important;
}
.checkout-box2 .vl {
  height: 25px !important;
}
.checkout-box2 .logos {
  padding-right: 0px !important;
}
.checkout-box2 .links {
  padding-left: 0px !important;
}

.btnSignout {
  display: -webkit-flex;
  display: flex;
  margin-left: 10px;
}

.lineTag {
  margin-top: 7px;
}

.cursorPointer {
  cursor: pointer;
}

ul.passwordPolicy {
  list-style-type: circle;
}

.marginTop25px {
  margin-top: 25px;
}

.chooseEnvText {
  width: 26%;
  margin-top: 12px;
}

.floatInherit {
  float: inherit;
}

.envDDL {
  width: 100%;
  display: block;
  margin-bottom: 8px;
  border: 2px solid;
}

.environmentDDL {
  width: 26%;
  margin-bottom: 50px;
  border: 2px solid;
}

.configureDIV {
  border: 3px solid;
  padding: 12px;
  margin-top: 16px;
  margin-bottom: 25px;
  border-style: groove;
}

.white-background {
  background-color: #ffffff;
}

.healthCheck {
  margin-left: -35px;
  margin-top: 39px;
  float: left;
}

.fontWeight900 {
  font-weight: 900;
}

.healthCheck li {
  margin-left: 15px;
  margin-right: 40px;
}

.masterCardSRCTxt {
  display: inline-block;
  float: left;
  margin-left: 38px;
}

.marginLeft39px {
  margin-left: 39px;
}

.fontWeight500 {
  font-weight: 500;
}

.greenColor {
  color: green;
  font-weight: 550;
}

.checkcolor {
  color: #098f09;
  font-size: 24px;
  margin-left: 20px;
}

.healthCheckpTag {
  float: left;
  margin-left: -4px;
  margin-bottom: 1px;
  font-weight: 900;
}
.chooseEnv{
  width: 15%;
}
.downloadSdk{
  width: 20%;
}
.iosSdk{
  margin-left: 15px;
}
/* .parameterValueTxt{
        float: left;
    display: flex;
    margin-left: -7px;
    margin-top: 20px;
    } */

/* .btn-enrollContinue{
        float: left;
        display: flex;
        margin-left: -25px;
    } */

.marginRghtminus25px {
  margin-right: -25px;
}

.height50px {
  height: 50px;
}

.addSRCDIV {
  float: left;
  margin-top: 38px;
  margin-left: -38px;
}

.dcfENVTxt {
  font-weight: 900;
  float: left !important;
}

.checboxVisibility {
  visibility: visible !important;
}

.w-1percent {
  width: 1%;
}

.textAlignCentre {
  text-align: center;
}

tbody {
  background-color: white !important;
  display: contents !important;
}

.yourCartTxt {
  margin-top: -20px;
  margin-bottom: 12px;
}

.marginBottom15px {
  margin-bottom: 15px;
}

#grid_testCaselogs {
  height: 450px;
}

.k-grid td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#content,
html,
body {
  height: 100%;
}

#left {
  float: right;
  height: 700px;
  width: 50%;
  overflow-y: scroll;
}

#right {
  width: 50%;
  float: left;
  height: 700px;
  overflow-y: scroll;
}

#dragbar {
  background-color: #cccccc;
  height: 1000%;
  float: right;
  width: 3px;
  cursor: col-resize;
}

#ghostbar {
  width: 3px;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  cursor: col-resize;
  z-index: 999;
}

.imgThumbnail {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

.imgThumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.selectedThumbnail {
  border: 2px solid rgb(20, 180, 10) !important;
}

.btnScreenShot {
  color: #fff;
  background-color: #46906b !important;
  border-color: #46906b !important;
}

.iconsHostLog {
  font-size: 24px;
  background-color: #46906b !important;
}

.screen-resize {
  margin-left: -145px;
  margin-top: -7px;
}

#topBtn {
  z-index: 5;
  padding: 3px;
  position: fixed;
  bottom: 45px;
  right: 60px;
  width: 50px;
  height: 50px;
  background: #46906b;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 30px;
  border-radius: 50%;
}

.LogTree li {
  font-weight: 600;
  line-height: 2.5em;
}

ul li,
ul li a,
ol li,
ol li a {
  font-size: 14px !important;
}

.indicator {
  cursor: pointer;
}

.API {
  background: #b9dfcd;
}

.srci {
  background-color: #f9ecbd;
}

.SDK {
  background: #76a7fa;
  color: white !important;
}

.dcf {
  background-color: darkkhaki;
  color: brown;
}

.label {
  border-radius: 5px;
  color: black !important;
}

.fa-times {
  color: #808080 !important;
}

.floatLeft {
  float: left;
}

.displayContent {
  display: contents;
}

.switchCol {
  float: left;
  display: -webkit-flex;
  display: flex;
  height: 42px;
}

.left55px {
  margin-left: 70px;
}

.payhuddleLogo {
  height: 100% !important;
}

.startDatepicker .react-datepicker-popper {
  -webkit-transform: inherit !important;
          transform: inherit !important;
  position: fixed !important;
  margin-left: 600px;
  margin-top: 109px;
}

.gobtn {
  background: #27ae60;
  padding: 6px;
  margin-left: 73px;
}

.left55px .react-datepicker-popper {
  -webkit-transform: inherit !important;
          transform: inherit !important;
  position: fixed !important;
  margin-left: 851px;
  margin-top: 109px;
}

.react-datepicker__input-container input {
  display: block;
  width: 180%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.configWindow .bm-burger-button {
  display: none;
}

.LogTree {
  margin-left: -32px;
}

.spinnerRow {
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  margin-left: 240px;
}

.marginLeftminus89px {
  margin-left: -89px;
}
.logSearch {
  margin-left: 80px;
}
.openBraceUL {
  margin-top: -24px !important;
  margin-left: -30px;
}

.openBraceLog {
  margin-left: -22px;
}

.openBraceLithosLog {
  margin-top: 19px !important;
}

.liClass {
  margin-bottom: 35px !important;
}

#topBtn {
  z-index: 10;
  padding: 3px;
  position: fixed;
  bottom: 45px;
  right: 15px;
  width: 50px;
  height: 50px;
  background: #46906b;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  font-size: 30px;
  border-radius: 50%;
}

html {
  overflow-x: hidden !important;
}

.breakWord {
  overflow-wrap: break-word;
}

.lithosTransaction,
.lithosTransaction ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.lithosTransaction ul {
  margin-left: 1em;
  position: relative;
}

.lithosTransaction ul ul {
  margin-left: 0.5em;
}

.lithosTransaction ul:before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /*border-left: 1px solid*/
}

.lithosTransaction li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  font-weight: 500;
  position: relative;
}

.lithosTransaction ul li:before {
  content: "";
  display: block;
  width: 10px;
  height: 0;
  /*border-top: 1px solid;*/
  margin-top: -1px;
  position: absolute;
  top: 1em;
  left: 0;
}

.lithosTransaction ul li:last-child:before {
  background: #fff;
  height: auto;
  top: 1em;
  bottom: 0;
}

.LTindicator {
  margin-right: 5px;
}

.lithosTransaction li a {
  text-decoration: none;
  color: black;
  margin-right: 10px;
  padding: 0 3px;
  font-size: 12px;
}
@media (max-width: 400px) {
  .switch-field {
    padding-left: 0px !important;
    margin-left: 15px;
  }
  .logSearch {
    margin-left: 0px;
    margin-right: 20px;
  }
  .innerLogsContainer .Search {
    margin-left: 15px;
    width: 100%;
    /* margin-right: 29px; */
  }
  #paymentCardPage,
  #profilePage,
  #paymentThankyou,
  #otpConfirmation,
  .IRACheckout {
    overflow-x: hidden;
  }
  #dcfIframe{
    width: 100% !important;
    margin: 0% 0% !important;
    height: 100% !important;
    overflow-y: auto;
  }
 .sdkIconCell{
    padding-left: 0px !important;
  }
  .sdkIconCell img{
    width: 25px !important;
  }
  .Toastify__toast-container {
    width: 100% !important;
  }
  .paramNameCol .paramName{
    padding-bottom: 12%;
    padding-top: 4%;
  }
  .developerMode{
    float:none;
  }
  /* Style for Safari Browser */
  @supports (-webkit-appearance: none) {
    .ConficCard {
      max-height: 300px !important;
      overflow-y: auto;
    }
  }
}
@media (max-width: 450px) {
  /* IRA Checkout */
  
  .navBrand {
    margin-left: 20%;
    font-weight: 600;
  }
  .payhuddleLogo {
    width: 110px;
  }
  .checkout-container {
    border: none;
  }
  .checkout-box {
    margin: 0% !important;
    width: 100% !important;
  }
  #btnConfiguration {
    margin-right: -60px;
    margin-top: -65px;
    padding: 5px 10px;
    position: fixed;
    right: 40px;
  }
  .checkout-navbar-title {
    font-size: 18px !important;
  }
  .SRCIcon {
    width: 50px !important;
  }
  .icon-badge-link svg {
    font-size: 20px !important;
  }
  .checkoutSubContainer {
    padding: 10px;
  }
  .checkout-page-yourcart-title {
    font-size: 18px !important;
    padding-left: 20px;
    padding-top: 10px !important;
  }
  .environment {
    font-weight: 600;
    margin-left: 20px;
    font-size: 18px;
  }
  .checkout-page-items {
    font-size: 15px !important;
  }
  .checkout-page-cart-item {
    width: 150px;
  }
  .checkout-page-cart-item-name {
    font-size: 15px !important;
  }
  .checkout-page-cart-item-jewels {
    font-size: 15px !important;
  }
  .checkout-page-cart-item-jewels-price {
    font-size: 15px !important;
  }
  .checkout-page-cart-item-jewels-total-price {
    font-size: 18px !important;
  }
  .hr-line-dot {
    margin-left: 20px;
  }
  .checkoutSubContainer .checkout-button {
    margin-left: 15px !important;
  }
  .checkout-page-logo {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 200px;
  }
  .innerLogsContainer {
    max-height: none;
  }
  .switchCol {
    display: inline;
    display: initial;
  }
  .switch-field {
    padding-left: 8%;
    float: none;
  }
  .innerLogsContainer .Search {
    margin-left: 25px;
    width: 70%;
  }
  .pagination {
    float: none;
    margin-top: 0px;
  }
  .pagination .active a {
    background-color: #007bff !important;
    color: white;
  }
  .active:after {
    content: none;
  }
  .LogTree li a {
    font-size: 13px !important;
  }
  .LogTree {
    margin-left: -10px;
  }
  .configWindow .bm-menu-wrap {
    width: 95% !important;
  }
  .spinnerRow {
    margin-left: 30%;
  }
  .spinnerRow img {
    width: 150px;
  }
  /* Add New card */
  .checkout-box2 .vl {
    height: 25px !important;
    position: absolute;
    margin-left: 20px;
    top: 10px;
  }
  .paymentcard-navbar .links {
    padding-right: 0px !important;
  }
  #paymentCardPage .container-fluid,
  #profilePage .container-fluid,
  #paymentThankyou .container-fluid,
  #otpConfirmation .container-fluid {
    padding-left: 0 !important;
  }
  .checkout-box2 {
    margin-top: 0px;
    margin-left: 5px;
    width: 100% !important;
  }
  .paymentcard-navbar .logos ul {
    padding-left: 0px;
  }
  .navbar-nav {
    display: inline;
  }
  .checkout-box2 .discoverLogo {
    margin-left: 5px;
  }
  .payment-confirmation-title {
    font-size: 16px !important;
  }
  #profilePage .checkout-container,
  #otpConfirmation .checkout-container {
    margin-bottom: 25px;
  }
  .otpBox {
    padding-left: 40% !important;
  }
  #loginPage .vl {
    height: 25px !important;
    position: absolute;
    margin-left: 20px;
    top: 10px;
  }
  #loginPage .srcLogo {
    height: 30px;
  }
  #loginPage .discoverLogo {
    height: 30px;
    margin-left: 5px;
  }
  #loginPage .box {
    margin-top: 50px;
  }
  #loginPage .footer {
    margin-top: 100px;
  }
  .accordion .card-body {
    overflow-x: auto;
  }
  .accordion .table td,
  .table th {
    padding-right: 0px;
    padding-left: 5px;
  }
  .configWindow .single-line {
    width: 200px !important;
    font-size: 12px;
  }
  .logSearch {
    margin-left: 0px;
    margin-right: 10px;
  }
  .innerLogsContainer .Search {
    width: 90%;
  }
  #paymentCardPage,
  #profilePage,
  #paymentThankyou,
  #otpConfirmation,
  .IRACheckout {
    overflow-x: hidden;
  }
  #dcfIframe{
    width: 100% !important;
    margin: 0% 0% !important;
    height: 100% !important;
    overflow-y: auto;
  }
.sdkIconCell{
    padding-left: 0px !important;
  }
  .sdkIconCell img{
    width: 25px !important;
  }
  .Toastify__toast-container {
    width: 100% !important;
  }
  .paramNameCol .paramName{
    padding-bottom: 12%;
    padding-top: 4%;
  }
  .developerMode{
    float:none;
  }
  /* Style for Safari Browser */
  @supports (-webkit-appearance: none) {
    .ConficCard {
      max-height: 300px !important;
      overflow-y: auto;
    }
  }
}
@media (min-width: 451px) and (max-width: 767px) {
  #paymentThankyou .merchant {
    margin-left: 20px !important;
  }
  .navBrand {
    margin-left: 15%;
    font-weight: 600;
    font-size: 20px;
  }
  .payhuddleLogo {
    width: 150px;
  }
  .checkout-container {
    border: none;
    padding-left: 50px !important;
    padding-right: 50px !important;
    margin-bottom: 20px !important;
  }
  .checkout-box {
    margin: 0% !important;
    width: 100% !important;
  }
  #btnConfiguration {
    margin-right: -60px;
    margin-top: -65px;
    padding: 5px 10px;
    position: fixed;
    right: 40px;
  }
  .checkout-navbar-title {
    font-size: 18px !important;
  }
  .SRCIcon {
    width: 50px !important;
  }
  .icon-badge-link svg {
    font-size: 20px !important;
  }
  .checkoutSubContainer {
    padding: 10px;
  }
  .checkout-page-yourcart-title {
    font-size: 18px !important;
    padding-left: 20px;
    padding-top: 10px !important;
  }
  .environment {
    font-weight: 600;
    margin-left: 20px;
    font-size: 18px;
  }
  .checkout-page-items {
    font-size: 15px !important;
  }
  .checkout-page-cart-item {
    width: 150px;
  }
  .checkout-page-cart-item-name {
    font-size: 15px !important;
  }
  .checkout-page-cart-item-jewels {
    font-size: 15px !important;
  }
  .checkout-page-cart-item-jewels-price {
    font-size: 15px !important;
  }
  .checkout-page-cart-item-jewels-total-price {
    font-size: 18px !important;
  }
  .hr-line-dot {
    margin-left: 20px;
  }
  .checkoutSubContainer .checkout-button {
    margin-left: 15px !important;
  }
  .checkout-page-logo {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 200px;
  }
  .innerLogsContainer {
    max-height: none;
  }
  .switchCol {
    display: inline;
    display: initial;
  }
  .switch-field {
    padding-left: 5%;
    float: none;
  }
  .innerLogsContainer .Search {
    margin-left: 25px;
    width: 70%;
  }
  .pagination {
    float: none;
    margin-top: 0px;
  }
  .pagination .active a {
    background-color: #007bff !important;
    color: white;
  }
  .active:after {
    content: none;
  }
  .LogTree li a {
    font-size: 13px !important;
  }
  .LogTree {
    margin-left: -10px;
  }
  .LogTree .noLogs {
    margin-left: -50px;
  }
  .configWindow .bm-menu-wrap {
    width: 95% !important;
  }
  .spinnerRow {
    margin-left: 35%;
  }
  .spinnerRow img {
    width: 150px;
  }
  /* Add New card */
  .checkout-box2 .vl {
    height: 25px !important;
    position: absolute;
    margin-left: 20px;
    top: 10px;
  }
  .paymentcard-navbar .links {
    padding-right: 0px !important;
  }
  #paymentCardPage .container-fluid,
  #profilePage .container-fluid,
  #paymentThankyou .container-fluid,
  #otpConfirmation .container-fluid {
    padding-left: 0 !important;
  }
  .checkout-box2 {
    margin-top: 0px;
    margin-left: 5px;
    width: 100% !important;
  }
  .paymentcard-navbar .logos ul {
    padding-left: 0px;
  }
  .navbar-nav {
    display: inline;
  }
  .checkout-box2 .discoverLogo {
    margin-left: 5px;
  }
  .payment-confirmation-title {
    font-size: 16px !important;
  }
  #profilePage .checkout-container,
  #otpConfirmation .checkout-container {
    margin-bottom: 25px;
  }
  .otpBox {
    padding-left: 40% !important;
  }
  #loginPage .vl {
    height: 25px !important;
    position: absolute;
    margin-left: 20px;
    top: 10px;
  }
  #loginPage .srcLogo {
    height: 30px;
  }
  #loginPage .discoverLogo {
    height: 30px;
    margin-left: 5px;
  }
  #loginPage .box {
    margin-top: 50px;
  }
  #loginPage .footer {
    margin-top: 100px;
  }
  .accordion .card-body {
    overflow-x: auto;
  }
  .accordion .table td,
  .table th {
    padding-right: 0px;
    padding-left: 5px;
  }
  .configWindow .single-line {
    width: 200px !important;
    font-size: 12px;
  }
  .logSearch {
    margin-left: 0px;
    margin-right: 10px;
  }
  .innerLogsContainer .Search {
    width: 90%;
  }
  .bm-burger-button {
    margin-top: 4px !important;
  }
  #paymentCardPage,
  #profilePage,
  #paymentThankyou,
  #otpConfirmation,
  .IRACheckout {
    overflow-x: hidden;
  }
  #dcfIframe{
    width: 100% !important;
    margin: 0% 0% !important;
    height: 100% !important;
    overflow-y: auto;
  }
.sdkIconCell img{
    width: 25px !important;
  }
  .androidSdk{
    margin-left: 10px !important;
  }
  .chooseEnv {
    width: 20%;
  }
  .Toastify__toast-container {
    width: 100% !important;
  }
  .paramNameCol .paramName{
    padding-bottom: 7%;
    padding-top: 1%;
  }
  .developerMode{
    float:none;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .checkout-container {
    -webkit-flex: 0 0 46%;
            flex: 0 0 46%;
    max-width: 46%;
  }
  .checkout-box {
    margin-left: 20px !important;
  }
  .LogWindow {
    -webkit-flex: 0 0 54%;
            flex: 0 0 54%;
    max-width: 54%;
  }
  .LogTree {
    overflow-x: auto;
  }
  .LogTree li {
    word-wrap: break-word;
  }
  .checkout-box2 {
    margin-left: 10px;
    width: 301px !important;
  }
  .switchCol {
    display: contents;
  }
  .logSearch {
    padding-left: 0px;
    margin-left: 0px;
  }
  .react-tabs__tab {
    padding: 6px 25px !important;
  }
  #dcfIframe{
    width: 50% !important;
    margin: 2% 25% !important;
  }

	.sdkIconCell{
    padding-left: 0px !important;
  }
  .sdkIconCell img{
    width: 25px !important;
  }
  .androidSdk{
    margin-left: 10px !important;
  }
  .paramNameCol .paramName{
    padding-bottom: 12%;
    padding-top: 4%;
  }
  .SDKParamValue{
    padding-right: 0px;    
  }
  .developerMode{
    float: none;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .checkout-box {
    margin-top: 15px !important;
    margin-left: 30px !important;
    width: 330px !important;
  }
  .checkout-container {
    -webkit-flex: 0 0 40%;
            flex: 0 0 40%;
    max-width: 40%;
  }
  .LogWindow {
    -webkit-flex: 0 0 60%;
            flex: 0 0 60%;
    max-width: 60%;
  }
  .logSearch {
    margin-left: 40px;
  }
  .switchCol {
    display: contents;
  }
  #paymentCardPage .checkout-box2,
  #profilePage .checkout-box2,
  #paymentThankyou .checkout-box2,
  #otpConfirmation .checkout-box2,
  #paymentThankyou .checkout-box2 {
    margin-left: 20px !important;
  }
  .sdkIconCell{
    padding-left: 0px !important;
  }
  .sdkIconCell img{
    width: 25px !important;
  }
  .androidSdk{
    margin-left: 10px !important;
  }
  .chooseEnv {
    width: 20%;
  }
  .paramNameCol .paramName{
    padding-bottom: 12%;
    padding-top: 4%;
  }
}

.spinnerPosition {
  position: absolute !important;
  height: auto;
  width: auto;
  margin-left: inherit;
  background: bottom;
}
.inputDivWidth,
[contenteditable] {
  -webkit-user-select: text !important;
}

.changesBasedOnEnv {
  margin-bottom:  10px;
}

.zeus {
  color: blueviolet !important;
}

.overflow-wrap-anymore{
  overflow-wrap: anywhere !important;
}
#dcfIframe{
  z-index: 9992 !important;
}
.paramNameCol .paramName{
  padding-bottom: 12%;
  padding-top: 5%;
}
.hideDownloadSrci{
  display: none;
}

.UiEditorPTag{
  font-weight: bolder;
  margin-bottom: 1px;
  margin-top: -4px;
  color: green;
  font-size: 16px;
}

.uiJsonEdit{
font-size: medium;
}

.configWindow .bm-menu{
  overflow: scroll !important;
  overflow-y: scroll !important;
  max-height: 1000px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  float: right;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.height50px {
  height: 40px;
}
