#loader-text {
	font-weight: 600;
	color: #f8f9fa;
}
#loader {
	margin-top: 330px;
	position: fixed;
	margin-left: 47%;
}
#loader-div {
	background-color: rgba(0, 0, 0, 0.5);
	height: 100%;
	width: 100%;
	position: absolute;
	z-index: 9991 ;
}
@media (min-width: 350px) and (max-width: 950px) {
	#loader-div {
		background-color: rgba(0, 0, 0, 0.5);
		height: 100%;
		width: 100%;
		position: absolute;
	}
	#loader {
		margin-top: 80%;
		position: fixed;
		margin-left: 44%;
	}
}
