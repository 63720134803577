.react-tabs__tab--selected{
    font-weight: bold;
}
.react-tabs__tab{
    padding: 6px 36px !important;
}

.inputBox{
    background: #fff;
    border: 2px solid gray;
    color: gray;
    font-weight: 600;
    padding-top: 10px !important;
    border-radius: 8px;
    height: 50px !important;
    padding-left: 20px !important;
    padding-right: 10px !important;
}

.inputBox[placeholder]:empty:before {
    content: attr(placeholder);
    color: grey; 
}

.inputBox[placeholder]:empty:focus:before {
    content: "";
}

.otpBox {
    padding-left: 120px !important;
}

.smallBox{
    background: #fff;
    border: 2px solid gray;
    color: gray;
    font-weight: 600;
    padding-top: 10px !important;
    border-radius: 8px;
    height: 50px !important;
    padding-right: 5px !important;   
    padding-left: none !important;   
    left: 30 !important;
    width: 100% !important;
}

.smallBox[placeholder]:empty:before {
    content: attr(placeholder);
    color: grey; 
}

.smallBox[placeholder]:empty:focus:before {
    content: "";
}

.card-details-form {
    left: 0px;
    right: 0px;
    padding-right: 4px;
}

.security-code {
    left: 15px;
}

[contenteditable="true"].single-line {
    white-space: nowrap;
    overflow: hidden;
} 
[contenteditable="true"].single-line br {
    display:none;

}
[contenteditable="true"].single-line * {
    display:inline;
    white-space:nowrap;
}

.testDataBox{
    background: #fff;
    border: 2px solid gray;
    color: gray;
    font-weight: 600;
    padding-top: 10px !important;
    border-radius: 8px;
    padding-right: 5px !important;   
    padding-left: none !important;   
    left: 30 !important;
    width: 100% !important;
}

