@media (min-width: 768px) {
  .checkout-page-container {
    width: 375px !important;
    
    border-top: 1px solid #b7b2b2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 1px solid #b7b2b2;
    border-right: 1px solid #b7b2b2;
    margin-top: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #b7b2b2;
  }

  .footer-navbar{
    width: 375px !important;
    margin: 0 auto;
    border-bottom: 1px solid #b7b2b2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: 1px solid #b7b2b2;
    border-right: 1px solid #b7b2b2;
    position: absolute;
    left: 0;
    right: 0;
    margin-bottom: 10px;
  }
}