/* // Fonts faces and family */
/* // @import url('://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700'); */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700');

/* $fa-font-path: './../assets/web-fonts';
$font-family: 'Open Sans', sans-serif; */

html,
body {
  font-family: 'Open Sans', sans-serif;
  background-color:  #ffffff;
  scroll-behavior: smooth;
  margin: 0;
}